// import gql from 'graphql-tag';
import { gql } from '@apollo/client';

const typeDefs = gql`
  type Query {
    me: User
    sidebarOpen: Boolean!
    applicationFilters: TableFilter!
  }
  type Mutation {
    toggleSidebar: CommonState
  }

  type TableFilter {
    id: ID!
    filters: ApplicationFilterInput
    first: Int!
    page: Int!
  }
`;

export default typeDefs;
