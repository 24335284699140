/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';

import { useUserInboxState } from './userInboxState';

const StateContext = createContext();

export function StateProvider({ children }) {
  const inboxState = useUserInboxState();

  return (
    <StateContext.Provider value={{ 
      ...inboxState, 
    }}>
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
  return useContext(StateContext);
}
