// import {  gql } from '@apollo/client';
import Fragemets from '../fragments';

const commonStateQuery =  (_root, variables, { getCacheKey, client }) => {
    const id = getCacheKey({ __typename: 'CommonState', id:'sidebar' })
    const commonState = client.readFragment({ fragment : Fragemets.toggleSidebar, id });
    return commonState; // Data returned from Mutation
  };


export default  {
    commonState: commonStateQuery
}