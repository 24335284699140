import React from 'react';
import { v4 as uuid } from 'uuid';
import ShortTextIcon from '@mui/icons-material/ShortText';
// import ListIcon from '@material-ui/icons/List';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import VideocamIcon from '@mui/icons-material/Videocam';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ListIcon from '@mui/icons-material/List';
import CodeIcon from '@mui/icons-material/Code';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import BallotIcon from '@mui/icons-material/Ballot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import Filter1Icon from '@mui/icons-material/Filter1';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import HighlightIcon from '@mui/icons-material/Highlight';
import CalendarViewDayIcon from '@mui/icons-material/CalendarViewDay';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PagesIcon from '@mui/icons-material/Pages';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import PeopleIcon from '@mui/icons-material/People';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import TabIcon from '@mui/icons-material/Tab';
import Icon from '../components/atoms/Icon';

export const FIELDS_ENUM = {
  TEXT_FIELD: 'text_field',
  TITLE: 'title', //  textfield - input
  MANDATORY_TITLE: 'mandatory_title',
  EMAIL: 'email',
  TEL: 'tel',
  DATETIME_FIELD: 'datetime_field',
  LINK: 'link',
  VIDEO_LINK: 'video_link',
  FIGURES: 'figures', // attachment
  AUDIO: 'audio',
  DEMONSTRATION: 'demonstration', // attachment
  PAPER: 'paper',
  MANDATORY_PAPER: 'mandatory_paper',
  ATTACHMENT: 'attachment',
  INSTRUMENTS: 'instruments',
  GENRES: 'genres',
  TOPICS_OF_INTEREST: 'topics_of_interest', // list
  LIST: 'list',
  HEADING: 'heading',
  GROUP_MEMBERS: 'group_members',
  COLLABORATORS_MEMBERS: 'collaborators_members',
  TOGGLE: 'toggle',
  LOCATION: 'location',
  CONFLICT_OF_INTEREST: 'conflict_of_interest', // list
  KEYWORDS: 'keywords',
  MULTIPLE_CHOICE: 'multiple_choice', // list
  STRENGHTS: 'strenghts', // text area
  WEAKNESSES: 'weaknesses', // text area
  QUESTIONS_FOR_THE_AUTHORS: 'questions_authors', // text area
  BEST_PAPER_AWARD: 'best_paper_award', // toggle
  WRITING_QUALITY: 'writing_quality', // text area
  ORIGINALITY: 'originality', // textarea
  NOVELTY_AND_EXCITEMENT: 'novelty_excitement', // list
  PAPER_LENGHT: 'paper_lenght', // textfield
  SUMMARY: 'summary', // textarea
  DIET_ALLERGIES: 'diet_allergies', // list  
  SUBMISSION_ID: 'submission_id', // textfield
  NATIONALITY: 'nationality', // textfield
  GENDER: 'gender',
  PRIMARY_JOB: 'primary_job', // textfield
  SHORT_QUESTION: 'short_question', // textfield
  LONG_QUESTION: 'long_question', // textarea
  MULTIPLE_QUESTIONS: 'multiple_questions', // list
  APPLICATION_LOCATION: 'application_location', // locatio
  COLLABORATORS: 'collaborators', // groupmembers
  SUPPLEMENTARY_MATERIALS: 'supplementary_materials', // attachment
  AUTHORS: 'authors', // group members 
  MANDATORY_AUTHORS: 'mandatory_authors', // group members 
  ABSTRACT: 'abstract', // textarea
  MANDATORY_ABSTRACT: 'mandatory_abstract',
  FIRST_NAME: 'first_name', // textfield 
  LAST_NAME: 'last_name', // textfield 
  FULL_NAME: 'full_name',
  ORGANIZATION: 'organization', // textfield
  OVERALL_EVALUATION: 'overall_evaluation', // textarea
  OVERALL_MERIT: 'overall_merit', // list
  REVIEWER_CONFIDENCE: 'reviewer_confidence', // list 
  CORRESPONDING_AUTHOR: 'corresponding_author'
};





export const SUBTYPE_ENUM = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'datetime',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  WEBSITE: 'website',
  SOUNDCLOUD: 'soundcloud',
  MULTIPLE: 'multiple',
  SINGLE: 'single',
  SINGLE_AUTHOR: 'single_author',
  MULTIPLE_AUTHORS: 'multiple_authors',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
  H4: 'h4',
  BODY1: 'body1',
  IMAGE: 'image',
  AUDIO: 'audio'
};

export const COLLABORATORS_MEMBERS = [
  {
    name: 'Full Name',
    slug: 'full_name',
    type: FIELDS_ENUM.TEXT_FIELD,
    icon: <ShortTextIcon />,
    default: true,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  },
  {
    name: 'Affiliation',
    slug: 'affiliation',
    type: FIELDS_ENUM.TEXT_FIELD,
    default: false,
    icon: <CalendarTodayIcon />,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  },
  {
    name: 'Email',
    slug: 'email',
    type: FIELDS_ENUM.EMAIL,
    default: false,
    icon: <AlternateEmailIcon />,
    subtype: null,
    choices: null,
  }
];


export const GROUP_MEMBERS = [
  {
    name: 'Full Name',
    slug: 'full_name',
    type: FIELDS_ENUM.TEXT_FIELD,
    icon: <ShortTextIcon />,
    default: true,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  },
  {
    name: 'Research expertise',
    slug: 'research_expertise',
    type: FIELDS_ENUM.TEXT_FIELD,
    default: false,
    icon: <RadioButtonCheckedIcon />,
    subtype: SUBTYPE_ENUM.RADIO,
    // choices: ['male', 'female', 'non-binary', 'prefer-not-to-say'],
    choices: { en: ['male', 'female', 'non-binary', 'prefer-not-to-say'] },
  },
  {
    name: 'Affiliation',
    slug: 'affiliation',
    type: FIELDS_ENUM.TEXT_FIELD,
    default: false,
    icon: <CalendarTodayIcon />,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  },
  {
    name: 'Email',
    slug: 'email',
    type: FIELDS_ENUM.EMAIL,
    default: false,
    icon: <AlternateEmailIcon />,
    subtype: null,
    choices: null,
  },
  {
    name: 'Corresponding author',
    slug: 'corresponding_author',
    type: FIELDS_ENUM.CORRESPONDING_AUTHOR,
    default: false,
    icon: <PhoneIcon />,
    subtype: SUBTYPE_ENUM.TEXT,
    choices: null,
  }
];

export const FIELDS = [
  {
    id: uuid(),
    type: FIELDS_ENUM.TOPICS_OF_INTEREST,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.KEYWORDS,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.DEMONSTRATION,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.CONFLICT_OF_INTEREST,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.LINK,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.ATTACHMENT,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.FIGURES,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.AUDIO,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.VIDEO_LINK,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.TEXT_FIELD,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.MULTIPLE_CHOICE,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.STRENGHTS,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.WEAKNESSES,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.QUESTIONS_FOR_THE_AUTHORS,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.BEST_PAPER_AWARD,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.WRITING_QUALITY,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.ORIGINALITY,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.NOVELTY_AND_EXCITEMENT,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.PAPER_LENGHT,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.SUMMARY,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.TEL,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.DIET_ALLERGIES,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.SUBMISSION_ID,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.NATIONALITY,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.GENDER,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.PRIMARY_JOB,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.SHORT_QUESTION,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.LONG_QUESTION,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.EMAIL,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.DATE,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.APPLICATION_LOCATION,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.COLLABORATORS,
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.SUPPLEMENTARY_MATERIALS,
  },
];

export const FIELD_OPTIONS = {
  [FIELDS_ENUM.TEXT_FIELD]: {
    name: 'Text Field',
    icon: <ShortTextIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      },
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.CORRESPONDING_AUTHOR]: {
    name: 'Text Field',
    icon: <ShortTextIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.TITLE]: {
    name: 'Title',
    icon: <TextFieldsIcon />,
  },
  [FIELDS_ENUM.MANDATORY_TITLE]: {
    name: 'Title',
    icon: <TextFieldsIcon />,
  },
  [FIELDS_ENUM.OVERALL_EVALUATION]: {
    name: 'Overall evaluation',
    icon: <ShortTextIcon />,
  },
  [FIELDS_ENUM.OVERALL_MERIT]: {
    name: 'Overall merit',
    icon: <CalendarViewDayIcon />,
    hasGrades: true,
  },
  [FIELDS_ENUM.REVIEWER_CONFIDENCE]: {
    name: 'Reviewer confidence',
    icon: <CalendarViewDayIcon />,
    hasGrades: true,
  },
  [FIELDS_ENUM.AUTHORS]: {
    name: 'Authors',
    icon: <PeopleIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.MANDATORY_AUTHORS]: {
    name: 'Authors',
    icon: <PeopleIcon />,
    hasChoices: false
  },
  [FIELDS_ENUM.SHORT_QUESTION]: {
    name: 'Short question',
    icon: <ShortTextIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      }
    ],
  },
  [FIELDS_ENUM.ABSTRACT]: {
    name: 'Abstract',
    icon: <ShortTextIcon />,
  },
  [FIELDS_ENUM.MANDATORY_ABSTRACT]: {
    name: 'Abstract',
    icon: <ShortTextIcon />,
  },
  [FIELDS_ENUM.LONG_QUESTION]: {
    name: 'Long question',
    icon: <ShortTextIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      }
    ],
  },
  [FIELDS_ENUM.SUMMARY]: {
    name: 'Summary',
    icon: <PagesIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.CONFLICT_OF_INTEREST]: {
    name: 'Conflict of interest',
    icon: <ShortTextIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      },
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.ORIGINALITY]: {
    name: 'Originality',
    icon: <HighlightIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      }
    ],
  },
  [FIELDS_ENUM.PAPER_LENGHT]: {
    name: 'Paper length',
    icon: <InsertDriveFileIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      }
    ],
  },
  [FIELDS_ENUM.WRITING_QUALITY]: {
    name: 'Originality',
    icon: <BrightnessAutoIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      }
    ],
  },
  [FIELDS_ENUM.STRENGHTS]: {
    name: 'Strenghts',
    icon: <TrendingUpIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.WEAKNESSES]: {
    name: 'Weaknesses ',
    icon: <TrendingDownIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.KEYWORDS]: {
    name: 'Keywords',
    icon: <CodeIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.ORGANIZATION]: {
    name: 'Organization',
    icon: <TabIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.EMAIL]: {
    name: 'Email',
    icon: <AlternateEmailIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.APPLICATION_LOCATION]: {
    name: 'Application location',
    icon: <LocationOnIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.COLLABORATORS]: {
    name: 'Collaborators',
    icon: <PeopleIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.TEL]: {
    name: 'Phone number',
    icon: <PhoneIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.DIET_ALLERGIES]: {
    name: 'Diet / Allergies',
    icon: <AccessibilityIcon />,
    hasChoices: true,
    subtypes: [
      {
        key: SUBTYPE_ENUM.RADIO,
        value: 'radio',
      },
      {
        key: SUBTYPE_ENUM.CHECKBOX,
        value: 'checkbox',
      },
    ],
  },
  [FIELDS_ENUM.SUBMISSION_ID]: {
    name: 'Submission ID(s)',
    icon: <ShortTextIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.NATIONALITY]: {
    name: 'Nationality',
    icon: <ShortTextIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.GENDER]: {
    name: 'Gender',
    icon: <ShortTextIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.PRIMARY_JOB]: {
    name: 'Primary job',
    icon: <ShortTextIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.DATETIME_FIELD]: {
    name: 'Date and Time',
    icon: <ShortTextIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.DATETIME,
        value: 'datetime',
      },
      {
        key: SUBTYPE_ENUM.DATE,
        value: 'date',
      },
      {
        key: SUBTYPE_ENUM.TIME,
        value: 'time',
      },
    ],
  },
  [FIELDS_ENUM.LINK]: {
    name: 'Link',
    icon: <InsertLinkIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.WEBSITE,
        value: 'website',
      },
      {
        key: SUBTYPE_ENUM.FACEBOOK,
        value: 'facebook',
      },
      {
        key: SUBTYPE_ENUM.TWITTER,
        value: 'twitter',
      },
      {
        key: SUBTYPE_ENUM.INSTAGRAM,
        value: 'instagram',
      },
      {
        key: SUBTYPE_ENUM.LINKEDIN,
        value: 'linkedin',
      },
      {
        key: SUBTYPE_ENUM.YOUTUBE,
        value: 'youtube',
      },
      {
        key: SUBTYPE_ENUM.SOUNDCLOUD,
        value: 'soundcloud',
      },
    ],
  },
  [FIELDS_ENUM.VIDEO_LINK]: {
    name: 'Video',
    icon: <VideocamIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.FIGURES]: {
    name: 'Figures',
    icon: <LocalSeeIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.AUDIO]: {
    name: 'Audio',
    icon: <MusicNoteIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      {
        key: SUBTYPE_ENUM.MULTIPLE,
        value: 'multiple',
      },
    ],
  },
  [FIELDS_ENUM.SUPPLEMENTARY_MATERIALS]: {
    name: 'Supplementary materials',
    icon: <MusicNoteIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.ATTACHMENT,
        value: 'attachment',
      },
      {
        key: SUBTYPE_ENUM.VIDEO_LINK,
        value: 'video',
      },
      {
        key: SUBTYPE_ENUM.IMAGE,
        value: 'attachment',
      },
      {
        key: SUBTYPE_ENUM.AUDIO,
        value: 'audio',
      },
      {
        key: SUBTYPE_ENUM.LINK,
        value: 'link',
      },
    ],
  },
  [FIELDS_ENUM.DEMONSTRATION]: {
    name: 'Demonstration',
    icon: <AttachFileIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      // {
      //   key: SUBTYPE_ENUM.MULTIPLE,
      //   value: 'multiple',
      // },
    ],
  },
  [FIELDS_ENUM.MANDATORY_PAPER]: {
    name: 'Paper',
    icon: <AttachFileIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      // {
      //   key: SUBTYPE_ENUM.MULTIPLE,
      //   value: 'multiple',
      // },
    ],
  },
  [FIELDS_ENUM.ATTACHMENT]: {
    name: 'Attachment',
    icon: <AttachFileIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.SINGLE,
        value: 'single',
      },
      // {
      //   key: SUBTYPE_ENUM.MULTIPLE,
      //   value: 'multiple',
      // },
    ],
  },
  [FIELDS_ENUM.INSTRUMENTS]: {
    name: 'Instruments',
    icon: <Icon icon="electric-guitar" />,
    hasChoices: false,
  },
  [FIELDS_ENUM.GENRES]: {
    name: 'Genres',
    icon: <QueueMusicIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.FIRST_NAME]: {
    name: 'First name',
    icon: <AccountBoxIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.LAST_NAME]: {
    name: 'Last name',
    icon: <AccountBoxIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.FULL_NAME]: {
    name: 'Full name',
    icon: <AccountBoxIcon />,
    hasChoices: false,
  },
  [FIELDS_ENUM.TOPICS_OF_INTEREST]: {
    name: 'Topics of interest',
    icon: <ListIcon />,
    hasChoices: true,
    subtypes: [
      {
        key: SUBTYPE_ENUM.RADIO,
        value: 'radio',
      },
      {
        key: SUBTYPE_ENUM.CHECKBOX,
        value: 'checkbox',
      },
    ],
  },
  [FIELDS_ENUM.BEST_PAPER_AWARD]: {
    name: 'Best paper award',
    icon: <Filter1Icon />,
    hasChoices: false
  },
  [FIELDS_ENUM.NOVELTY_AND_EXCITEMENT]: {
    name: 'Novelty and excitement',
    icon: <CalendarViewDayIcon />,
    hasChoices: true,
    subtypes: [
      {
        key: SUBTYPE_ENUM.RADIO,
        value: 'radio',
      },
      {
        key: SUBTYPE_ENUM.CHECKBOX,
        value: 'checkbox',
      },
    ],
  },
  [FIELDS_ENUM.MULTIPLE_CHOICE]: {
    name: 'Multiple choice',
    icon: <BallotIcon />,
    hasChoices: true,
    subtypes: [
      {
        key: SUBTYPE_ENUM.RADIO,
        value: 'radio',
      },
      {
        key: SUBTYPE_ENUM.CHECKBOX,
        value: 'checkbox',
      },
    ],
  },
  [FIELDS_ENUM.QUESTIONS_FOR_THE_AUTHORS]: {
    name: 'Questions for the authors',
    icon: <LiveHelpIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.TEXT,
        value: 'text',
      },
      {
        key: SUBTYPE_ENUM.TEXTAREA,
        value: 'textarea',
      },
    ],
  },
  [FIELDS_ENUM.HEADING]: {
    name: 'Text',
    icon: <TextFieldsIcon />,
    hasChoices: false,
    subtypes: [
      {
        key: SUBTYPE_ENUM.H4,
        value: 'heading',
      },
      {
        key: SUBTYPE_ENUM.BODY1,
        value: 'text',
      },
    ],
  },
  [FIELDS_ENUM.LOCATION]: {
    name: 'Location',
    icon: <LocationOnIcon />,
  },
  [FIELDS_ENUM.GROUP_MEMBERS]: {
    name: 'Group Members',
    icon: <PeopleIcon />,
  },
  [FIELDS_ENUM.COLLABORATORS_MEMBERS]: {
    name: 'Collaborators Members',
    icon: <PeopleIcon />,
  },
};

export const INDIVIDUALS_FIXED_FIELDS = [
  {
    id: 'c25de979-5163-48a1-9926-84b421734a15',
    type: 'text_field',
    // label: { en: 'full_name' },
    label: 'full_name',
    is_required: true,
    options: { subtype: SUBTYPE_ENUM.TEXT },
    choices: { en: [] },
    fixed: true,
  },
  {
    id: '2d6b9da4-6133-4541-bcb3-56d2d252e9fd',
    type: FIELDS_ENUM.EMAIL,
    // label: { en: 'email' },
    label: 'email',
    is_required: true,
    options: null,
    choices: { en: [] },
    fixed: true,
  },
  {
    id: 'ea1c1d2d-b7ec-4028-ab2f-2af7b75c4d00',
    type: FIELDS_ENUM.LOCATION,
    label: 'address',
    // label: { en: 'address' },

    is_required: true,
    // options: { subtype: SUBTYPE_ENUM.TEXTAREA },
    options: null,
    choices: { en: [] },
    fixed: true,
  },
];

export const BANDS_FIXED_FIELDS = [
  {
    id: 'c25de979-5163-48a1-9926-84b421734a15',
    type: 'text_field',
    // label: { en: 'full_name' },
    label: 'artist_name',
    is_required: true,
    options: { subtype: SUBTYPE_ENUM.TEXT },
    choices: { en: [] },
    fixed: true,
  },
  ...INDIVIDUALS_FIXED_FIELDS,

]

export const SUBMISSION_FIXED_FIELDS = [
  {
    id: uuid(),
    type: FIELDS_ENUM.MANDATORY_TITLE,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Title' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.MANDATORY_ABSTRACT,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Abstract' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.MANDATORY_PAPER,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Paper' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.MANDATORY_AUTHORS,
    child_fields: [{
      id: "b09cd683-2900-421b-8489-41268162b096",
      label: "Full Name",
      type: FIELDS_ENUM.TEXT_FIELD,
      slug: "full_name"
    }],
    choices: null,
    is_required: true,
    label: { en: 'Authors' },
    options: {
      subtype: "single_author"
    }
  },
];

export const REVIEW_FIXED_FIELDS = [
  {
    id: uuid(),
    type: FIELDS_ENUM.OVERALL_EVALUATION,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Overall evaluation' },
    options: {},
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.OVERALL_MERIT,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Overall merit' },
    options: {},
    grades: []
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.REVIEWER_CONFIDENCE,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Reviewer confidence' },
    options: {},
    grades: []
  }
];

export const REGISTRATION_FIXED_FIELDS = [
  {
    id: uuid(),
    type: FIELDS_ENUM.FULL_NAME,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Full name' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.EMAIL,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Email' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.ORGANIZATION,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Organization' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.LOCATION,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Location' },
    options: {}
  },
];

export const FEEDBACK_FIXED_FIELDS = [
  {
    id: uuid(),
    type: FIELDS_ENUM.FULL_NAME,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Full name' },
    options: {}
  },
  {
    id: uuid(),
    type: FIELDS_ENUM.EMAIL,
    child_fields: [],
    choices: null,
    is_required: true,
    label: { en: 'Email' },
    options: {}
  },
];

export const SYSTEM_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';