import { gql } from '@apollo/client';

const APPLICATION_PAYMENT = gql`
  fragment applicationPayment on Application {
    payment_info {
      # code
      amount
      currency
      coupon
      discount
      fee
      payment_option
      is_external
      discount_percent
      created_at
    }
  }
`;

const ApplicationFragments = {
  applicationPayment: APPLICATION_PAYMENT,
};

export default ApplicationFragments;
