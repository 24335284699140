export const ACCOUNT_BUSINESS_TYPE = {
    INDIVIDUAL:"INDIVIDUAL",
    COMPANY:"COMPANY",
    NON_PROFIT:"NON_PROFIT"
}

export const SOCIAL_TYPES = {
    FACEBOOK: 'FACEBOOK',
    TWITTER: 'TWITTER',
    YOUTUBE: 'YOUTUBE',
    LINKEDIN: 'LINKEDIN',
    INSTAGRAM: 'INSTAGRAM',
    SOUND_CLOUD: 'SOUND_CLOUD',

  };
  

export const SOCIAL_MENU = [
    { value: SOCIAL_TYPES.FACEBOOK, label: 'Facebook' },
    { value: SOCIAL_TYPES.TWITTER, label: 'Twitter' },
    { value: SOCIAL_TYPES.YOUTUBE, label: 'Youtube' },
    { value: SOCIAL_TYPES.LINKEDIN, label: 'LinkedIn' },
    { value: SOCIAL_TYPES.INSTAGRAM, label: 'Instagram' },
    { value: SOCIAL_TYPES.SOUND_CLOUD, label: 'SoundCloud' },

  ];