import CommonFragments from './common.fragment';
import OpportunityFragment from './opportunity.fragment';
import OrganisationFragment from './organisation.fragment';
import ApplicationFragments from './applications.fragment';
import UserFragment from './user.fragment'

const Fragments = {
  ...CommonFragments,
  ...OpportunityFragment,
  ...OrganisationFragment,
  ...ApplicationFragments,
  ...UserFragment
};

export default Fragments;
