export const APPLICATION_STATUS = {
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    SUBMITTED: 'Submitted',
    UNDER_REVIEW: 'Under_review',
    REVIEWED: 'Reviewed'
};

export const REVIEW_STATUS = {
    PENDING: 'Pending',
    REVIEWED: 'Reviewed'
};

export const REVIEWER_INVITATION_STATUS = {
    PENDING: 'Pending',
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected'
};