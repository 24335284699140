import { useState } from 'react';
import cookie from 'cookie'


/**
 * @returns {string|null}
 */
function getAccessToken() {
  return document && document.cookie 
    ? cookie.parse(document.cookie).token 
    : null
}


/**
 * 
 * @param {string} url 
 * @returns {[{Function}, Object]}
 */
export const useFileDownload = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const fetchFile = async () => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`
        }
      });
      const [, filename] = response.headers.get('Content-Disposition').split('=');

      const fileBlob = await response.blob();
      const a = document.createElement('a');
      a.href  =  window.URL.createObjectURL(fileBlob);;
      a.download = filename;
      document.body.appendChild(a);
      a.click();    
      a.remove();
    } catch(err) {
      setError(err);
    }

    setLoading(false);
  }

  return [fetchFile, {loading, error}];
};
