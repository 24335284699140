import { gql } from '@apollo/client';

import { initialData } from '../config/initial-data'

export const FILLTER_KEY = 'application-filters';

export const possibleInterfacesTypes =  {
  Field: [
    'BuilderField',
    'TextField',
    'SelectField',
    'RemoteSelectField',
    'FileField',
    'ValueField',
  ],
  ExternalAccount: [
    'BankAccountDTO',
    'CreditCardDTO'
  ]
}

export const initalizeCache = (cache) => {
  cache.writeQuery({
    query: gql`
      query {
        sidebarOpen
        networkStatus {
          isConnected
        }
        applicationFilters {
          filters {
            q
            sort {
              field
              asc
            }
          }
          first
          page
        }
      }
    `,
    data: {
      ...initialData
    },
  });
};
