import { gql } from '@apollo/client';



export const USER_ACCOUNT_BALANCE = gql`
  fragment userAccountBalance on AccountBalanceDTO {
    available {
      amount
      currency
    }
    total {
      amount
      currency
    }
    pending {
      amount
      currency
    }
  }
`;

const UserFragment = {
  userAccountBalance: USER_ACCOUNT_BALANCE,
};

export default UserFragment;