import React from 'react';
// import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import Link from 'next/link';

import { StyledImage, StyledSVG, StyledNonSVG } from './style';
// import PlaceholderImage from '../../../static/images/placeholder.svg';

const CustomImage = props => {
  const {
    src,
    alt,
    width,
    height,
    href,
    responsive,
    rounded,
    opacity,
    filter,
    onClick,
    svg,
    className,
    color,
    fitCover,
    draggable,
  } = props;

  let Cmp = (
    <StyledImage
      className={className}
      src={src}
      alt={alt || src}
      imageWidth={width}
      imageHeight={height}
      responsive={responsive}
      rounded={rounded}
      opacity={opacity}
      filter={filter}
      onClick={onClick}
      fitCover={fitCover}
      draggable={draggable}
      onError={e => {
        e.target.onerror = null;
        e.target.src = '/images/placeholder.svg';
        e.target.style.objectFit = 'cover';
      }}
    />
  );

  if (svg && typeof window !== 'undefined') {
    Cmp = (
      <StyledSVG
        className={className}
        src={src}
        alt={alt || src}
        width={width}
        height={height}
        responsive={responsive}
        rounded={rounded}
        opacity={opacity}
        filter={filter}
        onClick={onClick}
      >
        Your browser doesn&apos;t support XHR or inline SVGs.
      </StyledSVG>
    );
  }

  if (color && svg && typeof window === 'undefined') {
    Cmp = (
      <StyledNonSVG
        src={src}
        alt={alt || src}
        width={width}
        height={height}
        responsive={responsive}
        rounded={rounded}
        opacity={opacity}
        filter={filter}
        onClick={onClick}
        color={color}
        fitCover={fitCover}
        draggable={draggable}
      />
    );
  }

  const Clicable = (
    <Link href={href || '#0'} passHref>
      <a href="#0">{Cmp}</a>
    </Link>
  );

  // if (typeof window !== 'undefined') {
  //   if (svg) {
  //     return <React.Fragment>{href ? Clicable : Cmp}</React.Fragment>;
  //   }
  //   return <LazyLoad>{href ? Clicable : Cmp}</LazyLoad>;
  // }

  return <>{href ? Clicable : Cmp}</>;
};

CustomImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  href: PropTypes.string,
  responsive: PropTypes.bool,
  rounded: PropTypes.number,
  opacity: PropTypes.number,
  filter: PropTypes.string,
  onClick: PropTypes.func,
  svg: PropTypes.bool,
  color: PropTypes.string,
  fitCover: PropTypes.bool,
  draggable: PropTypes.bool,
};

CustomImage.defaultProps = {
  className: null,
  alt: '',
  href: null,
  responsive: false,
  rounded: null,
  opacity: null,
  filter: null,
  width: '100%',
  height: null,
  onClick: null,
  svg: null,
  color: null,
  fitCover: false,
  draggable: true,
};

export default CustomImage;
