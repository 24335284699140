import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const USER_INVITATIONS = gql`
  query userInvitations {
    userInvitations {
      id
      name
      email
      status
      opportunity {
        ...opportunityCard
      }
    }
  }
  ${Fragments.opportunityCard}
`;