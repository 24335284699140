import styled from 'styled-components';

export const Parent = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.parentWidth && `width: ${props.parentWidth}`};
  ${props => props.parentHeight && `height: ${props.parentHeight}`};
  ${props => props.onClick && `cursor: pointer`};
  ${props =>
    props.circle &&
    `
      border-radius: 50%;
    `}

  ${props =>
    props.bgColor &&
    `
      background-color: ${props.bgColor};
    `}
  
    ${props =>
      props.flipH &&
      `
        transform: scaleX(-1);
        filter: FlipH;
    `}

    ${({ inline }) =>
      inline &&
      `
        margin: auto;      
      `}
  

  svg {
    font-size: 1.7142857142857142rem;
    height:1em;
    width: 1em;
    ${props => props.parentWidth && `width: ${props.parentWidth}`};
    ${props => props.parentHeight && `height: ${props.parentHeight}`};
    overflow: visible;
    display: inline-block;
    transition: all 0.3s;
    ${props => {
      let color = 'inherit';
      if (props.svgColor === 'primary') {
        color = props.theme && props.theme.primary.main;
      } else if (props.svgColor === 'secondary') {
        color = props.theme && props.theme.secondary.main;
      } else if (props.svgColor) {
        color = props.svgColor;
      }

      return (
        color &&
        `
        rect,
        circle,
        ellipse,
        line,
        polygon,
        polyline,
        path {
          fill: ${color};
        }
      `
      );
    }}
  }
`;
