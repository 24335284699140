import { ApolloLink } from '@apollo/client';

export const authMiddleware = (getToken) =>
  new ApolloLink((operation, forward) => {
    if(!getToken()) {
      return forward(operation)
    }
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()}`,
      },
    }));

    return forward(operation);
  });
