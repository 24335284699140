import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@app/apollo/queries';

export const useUser = () => {
  const { data, loading, error } = useQuery(USER_QUERY);


  if (error) {
    // Handle the error appropriately, such as logging or showing an error message
    console.error('Error fetching user:', error);
    return {
      loading: false,
      isAuthenticated: false,
      user: null
    };
  }

  return {
    loading,
    isAuthenticated: data && data.me,
    user: data?.me,
  };
};
