import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const USER_QUERY = gql`
  query getUser {
    me {
      ...UserInformation
      is_verified
      has_reviews
    }
  }
  ${Fragments.userInfo}
`;

export const USER_BANK_ACCOUNT = gql`
  query userAccount($user: ID!) {
    userAccount(user: $user) {
      name
      email
      business_type
      account_id
      country_code
      default_currency
      payouts_enabled
      has_external_account
      is_disabled
      disabled_reason
      users {
        id
        created_at
      }
    }
    userAccountBalance(user: $user) {
      ...userAccountBalance
    }
  }
  ${Fragments.userAccountBalance}
`;

export const VERIFY_USER_ACCOUNT_LINK = gql`
  query verifyUserAccountLink($user: ID!) {
    verifyUserAccountLink(user: $user) {
      url
      is_done
      next
    }
  }
`;

export const RESOURCE_AUTHORIZATION_QUERY = gql`
  query can($input: AuthorizationInput!) {
    canAccess(input: $input)
  }
`;


export const GET_SIDEBAR_STATUS = gql`
  {
    sidebarOpen @client
  }
`;

export const GET_APPLICATIONS_FILTER = gql`
  {
    applicationFilters @client {
      filters {
        q
        sort {
          field
          asc
        }
      }
      first
      page
    }
  }
`;

export const GET_REFUND_REQUESTS_FILTER = gql`
  {
    refundRequestsFilters @client {
      first
      page
    }
  }
`;
