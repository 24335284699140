import { rgba } from "polished";

export const colors = Object.freeze({
  main: '#091744',
  primary: {
    light: '#C5CAE9',
    main: '#5C6BC0',
    dark: '#1A237E',
    contrastText: '#ffffff',
    navBar: '#1976D2',
    navBarContent: '#0D47A1'
  },
  secondary: {
    light: '#FAFAFA',
    main: '#616161',
    dark: '#4f4d4d',
    contrastText: '#ffffff',
  },
  green: '#4CAF50',
  lightGreen: '#67C30',
  blue: '#2196F3',
  yellow: '#FFC107',
  // red: '#F44336',
  lightGray: '#ecf0f1',
  white: '#fff',
  lightSilver: '#fafafa',

  blueColors: {
    300: '#1565C0',
    800: '#283593'
  },

  // primaryColor: {
  //   10: '#F6FAFF',
  //   25: '#EDF5FF',
  //   50: '#DCECFF',
  //   100: '#BCDBFF',
  //   200: '#8FBEF4',
  //   300: '#64A4ED',
  //   400: '#3E89DD',
  //   500: '#3578C2',
  //   600: '#306FB4',
  //   700: '#2962A0',
  //   800: '#285D98',
  //   900: '#255181',
  // },

  primaryColor: {
    10: '#E8EAF6',
    25: '#E8EAF6',
    50: '#E8EAF6',
    100: '#C5CAE9',
    200: '#9FA8DA',
    300: '#7986CB',
    400: '#5C6BC0',
    500: '#3F51B5',
    600: '#3949AB',
    700: '#303F9F',
    800: '#283593',
    900: '#1A237E',
  },

  secondaryColor: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    150: '#DADADA',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121'
  },

  grey: {
    200: '#b0bec5',
    300: '#90a4ae',
    400: '#78909c',
    500: '#607d8b',
    600: '#546e7a',
    700: '#455a64',
    800: '#37474f',
    900: '#263238',
  },

  greenShades: {
    50: '#edf8e9',
    100: '#d2eec7',
    200: '#b5e2a3',
    300: '#96d67e',
    400: '#7ecd61', // secondary main
    500: '#66c445',
    600: '#57b43d',
    700: '#42a033',
    800: '#2d8b29',
    900: '#006918',
    A100: '#F8FFF6',
    A200: '#D0FFC3',
    A400: ' #A7FF90',
    A700: '#93FF77',
  },
  red: {
    50: '#FEEBEE',
    100: '#B3E5FC',
    200: '#81D4FA',
    300: '#4FC3F7',
    400: '#29B6F6',
    500: '#03A9F4',
    600: '#039BE5',
    700: '#0288D1',
    800: '#0277BD',
    900: '#01579B',
    A100: '#80D8FF',
    A200: '#40C4FF',
    A400: '#00B0FF',
    A700: '#0091EA',
  },
  sciveniaGrey: {
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  gradient: {
    1: 'linear-gradient(270deg, #231B42 -0.56%, #B959A9 4.68%, #6854E0 30.87%, #2BB1DB 63.34%, #4F4087 79.57%, #AEAF63 95.81%, #231B42 100%)',
    2: 'linear-gradient(115.26deg, #352775 0.88%, #274B75 0.89%, #18597A 23.11%, #4481DB 63.22%, #44DBD2 89.24%, #2196F3 104.97%)',
    3: 'linear-gradient(266.22deg, #352775 -2.29%, #274B75 -2.28%, #18597A 25.69%, #4481DB 76.2%, #44DBD2 124.24%, #2196F3 128.76%)',
    4: 'linear-gradient(224.32deg, #352775 -12.99%, #274B75 -12.97%, #18597A 18.06%, #4481DB 74.1%, #44DBD2 127.4%, #2196F3 132.42%)',
    5: 'linear-gradient(171.2deg, #87B1F2 4.79%, #CCDFEB 90.74%, #E4EFE9 119.09%, #E4EAEF 119.09%)',
    6: 'linear-gradient(256.92deg, #87B1F2 6.09%, #CCDFEB 104.14%, #E4EFE9 118.31%, #E4EAEF 118.31%);',
    7: 'linear-gradient(90deg, #0D47A1 0%, #E4EFE9 100%)',
    background: 'linear-gradient(357.7deg, #0A1744 6.63%, #44DAD2 211.43%, #30C5D2 274.12%)',
    main: 'linear-gradient(171.2deg, rgba(9, 23, 68, 0.5) 0%, rgba(9, 23, 68, 1) 25%, rgba(9, 23, 68, 1) 75%, rgba(9, 23, 68, 0.5) 100%)'
  },
  common: {
    black_60: '#666',
    black: '#000',
    rating: '#ffb400',
    footer: '#1f2326',
    inBlack: rgba('#000000', 0.54),
    divider: rgba('#212121', 0.15),
  },
  blueGrey: {
    300: '#90A4AE',
    400: '#78909C',
  },
  goodAlert: '#67BF4E',
  badAlert: '#E75055'
});
