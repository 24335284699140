import cookie from 'cookie'

import { i18n } from '../i18n';


/**
 * Get the user token from cookie
 * @param {Object} ctx
 */
export function getToken(ctx) {
    return () => {
        let cookieString = null;
        if (ctx && ctx.req && ctx.req.headers) {
            cookieString = ctx.req.headers.cookie
        }

        if (typeof document !== 'undefined' && document.cookie) {
            cookieString = document.cookie;
        }
        return cookieString ? cookie.parse(cookieString).token : ""
    }

}

/**
 * Get the user locale from cookie
 * @param {Object} req
 */
export function getLocale(ctx) {
    return () => ctx && ctx.req ? ctx.req.language : i18n.language
}

