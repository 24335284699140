import { ApolloLink } from '@apollo/client';

export const localeMiddleware = (getLocale) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'X-localization': getLocale(),
      },
    }));

    return forward(operation);
  });
