/* eslint-disable prefer-destructuring */

export const APOLLO_STATE = "__APOLLO_STATE__";
export const GRAPHQL_URI = process.env.NEXT_PUBLIC_API_ENDPOINT;
export const SERVER_PUBLIC_URL = process.env.NEXT_PUBLIC_SERVER_PUBLIC_URL;

// export const STRIPE_CLIENT = process.env.NEXT_PUBLIC_STRIPE_CLIENT_ID;
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY;
export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN;
export const GOOGLE_MAP_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY;
export const RECAPATCHA_KEY = process.env.NEXT_PUBLIC_RECAPATCHA_KEY;

