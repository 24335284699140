import { gql } from '@apollo/client';

export const SEARCH_SCIENTIST = gql`
  query SearchScientist($input: ScientistFilterInput, $first: Int!) {
    scientistSearch(input: $input, first: $first) {
      data {
        slug
        id
        name
        email
        user {
          avatar {
            name
            path
          }
        }
        expertise_areas {
          name
          slug
        }
        research_interests
        location {
          name
          country_code
          formatted_address
        }
        university
        employer
      }
    }
  }
`;
