import { gql } from '@apollo/client';

export const SEARCH_ORGANISATION = gql`
  query SearchOrganisation($first: Int!, $filters: OrganisationFilterInput) {
    organisationSearch(first: $first, input: $filters) {
      data {
        id
        slug
        name
        email
        city {
          name
          id
        }
        social_links {
          url
        }
        logo {
          name
          path
        }
      }
    }
  }
`;
