import styled, { css } from 'styled-components';
import SVG from 'react-inlinesvg';

const commonStyles = css`
	${props =>
		props.responsive &&
		`
		display: block;
		max-width: 100%;
		height: auto;
	`}

	${props =>
		props.rounded &&
		`
		border-radius: ${props.rounded}px;
	`}

	${props =>
		props.opacity &&
		`
		opacity: ${props.opacity};
		filter: alpha(opacity=${props.opacity * 100});
	`}

	${props =>
		props.filter &&
		`
		filter: ${props.filter};
	`}

	${props =>
		props.imageWidth &&
		`
		width: ${props.imageWidth};
	`}

	${props =>
		props.imageHeight &&
		`
		height: ${props.imageHeight};
	`}

	${props =>
		props.onClick &&
		`
		cursor: pointer;
	`}
`;

const StyledImage = styled.img`
  ${commonStyles}
  ${props =>
		props.fitCover &&
		`
		object-fit: cover;
	`}
`;

const StyledSVG = styled(SVG)`
	svg{
		${commonStyles}
		
		${props =>
		props.width &&
		`
			width: ${props.width};
		`}

		${props =>
		props.height &&
		`
			height: ${props.height};
		`}
	}
`;

const StyledNonSVG = styled.div`
	${commonStyles}
	
	${props =>
		props.width &&
		`
		width: ${props.width};
	`}

	${props =>
		props.height &&
		`
		height: ${props.height};
	`}

	${props =>
		props.color &&
		`
		background-color: ${props.color};
	`}
	
	${props =>
		props.src &&
		`
		mask: url(${props.src}) no-repeat center;
	`}

	${props =>
		props.fitCover &&
		`
		object-fit: cover;
	`}
`;

export { StyledImage, StyledSVG, StyledNonSVG };
