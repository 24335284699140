const NextI18Next = require('next-i18next').default;
// const path = require('path')

module.exports = new NextI18Next({
  defaultLanguage:'en',
  otherLanguages: [ 'es', 'de', 'fr','nl','it', 'pt','hr','ar', 'ru', 'fi'],
  localeSubpaths: {
    en: 'en',
    fr: 'fr',
    es: 'es',
    de: 'de',
    it: 'it',
    nl: 'nl',
    pt: 'pt',
    hr: 'hr',
    ar: 'ar',
    ru: 'ru',
    fi: 'fi'
  },
  // localePath: path.resolve('./public/static/locales'),
  ignoreRoutes: ['/_next/', '/static/', '/public/'],
  useSuspense: true,
});
