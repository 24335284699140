import { gql } from '@apollo/client';
import Fragments from '../fragments';

export const COUNTRIES_QUERY = gql`
  query getCountries {
    countries {
      id
      name
      code
    }
  }
`;

export const CITIES_QUERY = gql`
  query filterCities($query: String!) {
    filterCities(query: $query) {
      id
      name
      country {
        id
        name
      }
    }
  }
`;

export const GENRES_QUERY = gql`
  query getGenres {
    genres {
      id
      name
      slug
    }
  }
`;

export const INSTRUMENTS_QUERY = gql`
  query getInstruments {
    instruments {
      id
      name
      slug
      created_at
      parent {
        name
      }
      children {
        name
      }
    }
  }
`;

export const DOMAINS_QUERY = gql`
  query getDomains {
    domains {
      id
      name
      slug
      created_at
      subdomains {
        id
        name
        slug
        created_at
      }
    }
  }
`;

export const OPPORTUNITY_METADATA_QUERY = gql`
  query getMeta($parent_id: String) {
    genres(orderBy: { field: SLUG, order: ASC }) {
      id
      name
      slug
    }
    domains {
      id
      name
      slug
      created_at
      subdomains {
        id
        name
        slug
        created_at
      }
    }
    types(orderBy: { field: SLUG, order: ASC }) {
      id
      name
      slug
    }
    countries {
      id
      name
      code
    }
    instruments(parent_id: $parent_id, orderBy: { field: SLUG, order: ASC }) {
      id
      name
      slug
      created_at
      children(orderBy: { field: SLUG, order: ASC }) {
        id
        name
        slug
      }
    }
    languages(orderBy: { field: SLUG, order: ASC }) {
      id
      name
      slug
      created_at
      is_supported
    }
    myOrganisations {
      id
      slug
      name
      logo {
        name
        path
        thumb
      }
    }
    eventTypes(orderBy: { field: SLUG, order: ASC }) {
      id
      name
      slug
    }
    myTracks {
      id
      track_name
      track_admin
      track_description
      slug
    }
  }
`;

export const FORM_SECTIONS_QUERY = gql`
  query ($target: TargetUser!) {
    formSections(target: $target) {
      name
      slug
      fields {
        id
        name
        is_custom
        type
        ... on BuilderField {
          is_required
        }
        ... on SelectField {
          is_multiple
          choices
        }
        ... on TextField {
          minLength
          maxLength
        }

        ... on RemoteSelectField {
          is_multiple
        }
        ... on FileField {
          size
          count
          accept
        }
        ... on LinkField {
          preview_source
        }
      }
    }
  }
`;

export const FORM_SECTIONS_CACHED_QUERY = gql`
  query ($target: TargetUser!) {
    formSections(target: $target) @client {
      name
      slug
      #     target
      fields {
        id
        name
        is_custom
        type
        ... on SelectField {
          is_multiple
          choices
        }
        ... on TextField {
          minLength
          maxLength
        }

        ... on RemoteSelectField {
          is_multiple
        }
        ... on FileField {
          size
          count
          accept
        }
        ... on LinkField {
          preview_source
        }
      }
    }
  }
`;

export const ORGAINSATION_ACCOUNT_QUERY = gql`
  query ($organisation: ID!) {
    organisationAccount(organisation: $organisation) {
      id
      name
      email
      default_currency
      charges_enabled
      payouts_enabled
      organisations {
        id
      }
      created_at
    }
  }
`;

export const PAYMENT_COUNTRIES = gql`
  query {
    paymentCountries {
      ...countriesInfo
    }
  }
  ${Fragments.countriesInfo}
`;

export const SCIENTIST_METADATA_QUERY = gql`
  query ScientistMetadata($input: ScientistFilterInput, $first: Int!) {
    scientistSearch(first: $first) {
      data {
        id
        name
        email
        profile_image {
          name
        }
        location {
          formatted_address
        }
        university
        research_interests
      }
    }
  }
`;
