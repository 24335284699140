import { gql } from '@apollo/client';

export const ACCOMMODATIONS_QUERY = gql`
  query accommodations(
    $checkin: String, 
    $checkout: String, 
    $minPrice: String, 
    $maxPrice: String, 
    $destId: String,
    $childCount: Int,
    $adultCount: Int
    $facilities: [String],
    $others: [String],
    $review_score: Int,
    $distance: Int,
    $currency: String
    $property_types: [Int]
    $rooms: Int
    $free_cancelation: Boolean
    $breakfast_included: Boolean
  ) {
    accommodations(
      checkin: $checkin
      checkout: $checkout
      minPrice: $minPrice
      maxPrice: $maxPrice
      destId: $destId
      childCount: $childCount
      adultCount: $adultCount
      facilities: $facilities
      others: $others
      review_score: $review_score
      distance: $distance
      currency: $currency
      property_types: $property_types
      rooms: $rooms
      free_cancelation: $free_cancelation
      breakfast_included: $breakfast_included
    ) {
      id
      hotel_name_trans
      url
      timezone
      is_free_cancellable
      main_photo_url
      min_total_price
      max_photo_url
      max_1440_photo_url
      country_trans
      country_trans
      countrycode
      accommodation_type
      default_language
      distance_to_cc
      address_trans
      city_name_en
      city_in_trans
      district
      districts
      is_beach_front
      currencycode
      hotel_facilities
      class
      review_score
      review_score_word
      price_is_final
      soldout
      hotel_include_breakfast
      mobile_discount_percentage
      is_mobile_deal
      address
      zip
      longitude
      latitude
    }
  },
`;
export const DESTINATION_ID_QUERY = gql`
  query destinationId($city: String, $country: String) {
    destinationId(city: $city, country: $country) {
      id
    }
  }
`;
