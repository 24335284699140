// eslint-disable-next-line import/no-self-import
import Echo from 'laravel-echo';
import socketio from 'socket.io-client';
import cookie from 'cookie';

let token = '';

if (typeof window !== 'undefined') {
  const cookies = cookie.parse(document.cookie);
  token = cookies.token || '';
}

function createEchoInstanceIfSocketHostAvailable() {
  if (process.env.NEXT_PUBLIC_SOCKET_SERVER_HOST) {
    return new Echo({
      broadcaster: 'socket.io',
      host: process.env.NEXT_PUBLIC_SOCKET_SERVER_HOST,
      client: socketio,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  }

  console.warn('Socket URL not found');
  return null;
}

const echo = createEchoInstanceIfSocketHostAvailable();

export default echo;
