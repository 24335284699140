import { gql } from '@apollo/client'

export const UPGRADE_STATUS = gql`
  query upgradeStatus {
    upgradeStatus {
      id
      status
    }
  }
`;

export const SCIENTIST_PROFILE = gql`
  query scientistProfile($slug: String!) {
    scientistProfile(slug: $slug) {
      id
      name
      slug
      user {
        id
        name
        avatar {
          thumb
        }
      }
      email
      phone
      field
      degree
      university
      location {
        reference_id
        formatted_address
      }
      employer
      website
      social_links {
        type
        url
      }
      expertise_areas {
        id
        name
      }
      skills
      research_interests
      projects {
        id
        title
        slug
        start_date
        end_date
        description
        labs {
          formatted_address
        }
        collaborators {
          name
          affiliation
          email
        }
      }
      publications {
        id
        title
        slug
        authors {
          name
          affiliation
          email
        }
        journal
        doi
        date
        type
      }
    }
  }
`


