import { gql } from '@apollo/client';

export const MESSAGES_QUERY = gql`
  query (
    $friendId: String
    $conversationId: Int!
  ) {
    userMessages(
      friend_id: $friendId
      conversation_id: $conversationId
    ) {
      id
      message
      sender_id
      receiver_id
      conversation_id
    }
  }
`;

export const GET_CONVERSATIONS = gql`
  query {
    userConversations {
      id,
      user{
        id,
        name,
        email,
        avatar {
          name
          path
          placeholder
          thumb
        }
      }
      friend {
        id,
        name,
        email
        avatar {
          name
          path
          placeholder
          thumb
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query {
    users {
      id
      name
    }
  }
`;

