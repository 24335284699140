/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react'
import fetch from 'isomorphic-unfetch';

import { ApolloClient, InMemoryCache, from } from '@apollo/client';
// import { concatPagination } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client';
import isEqual from 'lodash/isEqual';
import merge from 'deepmerge';


import { authMiddleware, ErrorLink, localeMiddleware } from '@app/apollo/middleware';
import typeDefs from '@app/apollo/localSchema';
import { initalizeCache, possibleInterfacesTypes } from '@app/apollo/apolloStore';
import { resolvers } from '@app/apollo/resolvers';

import { GRAPHQL_URI } from '@app/constants';
import { getLocale, getToken } from './helpers';


export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const isServer = typeof window === "undefined";

let apolloClient;

// This is for matching multipart request for uploading files.
const httpLink = createUploadLink({
  uri: GRAPHQL_URI,
  credentials: 'same-origin',
  fetch,
});

function createApolloClient(pageContext) {
  return new ApolloClient({
    ssrMode: isServer,
    link: from([
      authMiddleware(getToken(pageContext)),
      localeMiddleware(getLocale(pageContext)),
      ErrorLink,
      httpLink
    ]),
    cache: new InMemoryCache({
      possibleTypes: possibleInterfacesTypes,
    }),
    connectToDevTools: true,
    resolvers,
    typeDefs
  })
}

export function initializeApollo(initialState = null, pageContext) {
  const _apolloClient = apolloClient ?? createApolloClient(pageContext)
  
  if (!apolloClient) {
    initalizeCache(_apolloClient.cache);
  }

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  } 
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}